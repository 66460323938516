import PropTypes from "prop-types";
import banner from "../images/banner2.png";
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import React, { useState } from 'react';
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleDown, faArrowAltCircleUp} from "@fortawesome/free-solid-svg-icons"
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	button: {
	  backgroundColor: 'transparent',
	  color: '#fff',
	  '&:hover': {
		backgroundColor: 'transparent',
		color: '#fff',
	},
  }})

function Header() {
  const classes = useStyles()
  const [displayBanner, setDisplayBanner] = useState(true);
  const [arrow, setArrow] = useState(faArrowAltCircleDown)

  const handleClose  = () => {
    if (displayBanner === true) {
      setArrow(faArrowAltCircleUp)
    }
    else {
      setArrow(faArrowAltCircleDown)
    }
		setDisplayBanner(!displayBanner)
	}

  return (
    <Box className="header" sx={{paddingTop: 0}}>
      {/* <Button id="header-arrow" className={classes.button} sx={{position: 'absolute', marginTop: '0px', right: '0px', color: 'white'}} onClick={handleClose}>
        <FontAwesomeIcon className="header-displayBanner-arrow" icon={arrow}/>
      </Button> */}
      {displayBanner ? (
      <Link id="header" href="/" >
        <Box 
        id="header-img" 
        component="img" 
        sx={{width:'100%',
        // height:'100vh'   
      }} 
        alt="Welcome logo" 
        src={banner}
        />
      </Link>) : null}
    </Box>
  )
}

export default Header
