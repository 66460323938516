const aws_exports = {
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_OZNmqllqk",
    userPoolWebClientId: "td0snmaesbclnnlij93oblqid",
    identityPoolId: "us-east-1:49d47c94-1ea1-40db-96e6-877ff19931f0"
  },
  API: {
    endpoints: [
      { name: "code", endpoint: "https://0ypxq2d0pd.execute-api.us-east-1.amazonaws.com/PROD/" },
      { name: "codev2", endpoint: "https://xcy0fqqsyg.execute-api.us-east-1.amazonaws.com/PROD/" }
    ]
  },
  Websocket: "wss://mq0lwcjz20.execute-api.us-east-1.amazonaws.com/PROD"
}
export default aws_exports
